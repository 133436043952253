import { http } from "../../services";

export default class WebsiteService {

    getWebsiteDetail() {
        return new Promise((res, rej) => {
            var domainname = window.location.host;
            var options = {'domain' : domainname};
            http.createReq(`/frontend/get_website_detail`, options).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

}