// eslint-disable-next-line import/no-anonymous-default-export
export default {
  siteName: 'WebWizards',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} Copyright. All rights reserved.`,
  studentCR: `Copyright © ${new Date().getFullYear()} - All Rights Reserved.`,
  enableAnimatedRoute: false,
  apiUrl: 'https://12houronlineapi.metrotrafficschool.com/api/',
  //apiUrl: 'http://12hour.local/api/',
  // apiUrl: 'https://lmsapi.webwizardsusa.com/',
  // apiUrl: 'http://12hoursadi.local/api',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
