import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { browserHistory } from '@iso/lib/helpers/history';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => (
  <BrowserRouter history={browserHistory}>
    <Provider store={store}>
      <AppProvider>
        <>
          <Routes />
        </>
      </AppProvider>
    </Provider>
  </BrowserRouter>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
