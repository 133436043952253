import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import useWindowSize from '@iso/lib/hooks/useWindowSize';
import ThemeSwitcher from '@iso/containers/ThemeSwitcher/ThemeSwitcher';
import Loader from '@iso/components/utility/loader';
import siteConfig from '@iso/config/site.config';

import GlobalStyles from '@iso/assets/styles/globalStyle';
import { PUBLIC_ROUTE, STUDENT_ROUTE } from './route.constants';
import { StudentContainer, StudentGlobalStyles } from './containers/Student/Student.styles';
import TopbarStudent from '@iso/containers/Topbar/TopbarStudent';

import { Layout } from 'antd';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const { Content, Footer } = Layout;

const styles = {
  layout: { flexDirection: 'row', overflowX: 'hidden' },
};

const publicRoutes = [
  // {
  //   path: PUBLIC_ROUTE.LANDING,
  //   exact: true,
  //   component: lazy(() => import('@iso/containers/Pages/Landing/Landing')),
  // },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
  // Students
  {
    path: STUDENT_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/Login')),
    isStudent: true,
  },
  {
    path: STUDENT_ROUTE.LOGIN,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/Login')),
    isStudent: true,
  },
  {
    path: STUDENT_ROUTE.REGISTER,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/Register')),
    isStudent: true,
  },
  {
    path: STUDENT_ROUTE.ACCOUNT,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/Account')),
    isStudent: true,
  },
  {
    path: STUDENT_ROUTE.COURSE,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/Course')),
    isStudent: true,
  },
  {
    path: STUDENT_ROUTE.TIMER,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/Timer')),
    isStudent: true,
	},
  {
    path: STUDENT_ROUTE.SHORT_BREAK,
    exact: true,
    component: lazy(() => import('@iso/pages/frontend/ShortBreak')),
    isStudent: true,
	},
  {
		path: STUDENT_ROUTE.QUIZ,
		exact: true,
		component: lazy(() => import('@iso/pages/frontend/Quiz')),
    isStudent: true,
	},
	{
		path: STUDENT_ROUTE.ADMIN_LOGIN,
		exact: true,
		component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
		path: STUDENT_ROUTE.CHAPTER_QUIZ,
		exact: true,
		component: lazy(() => import('@iso/pages/frontend/ChapterQuizDetails')),
    isStudent: true,
  },
  {
		path: STUDENT_ROUTE.QUIZ_REVISION,
		exact: true,
		component: lazy(() => import('@iso/pages/frontend/Quiz')),
    isStudent: true,
	},
  {
		path: STUDENT_ROUTE.PAYMENT_TRANSACTION,
		exact: true,
		component: lazy(() => import('@iso/pages/frontend/PaymentTransaction')),
    isStudent: true,
	},
  {
		path: STUDENT_ROUTE.EDIT_PROFILE,
		exact: true,
		component: lazy(() => import('@iso/pages/frontend/EditProfile')),
    isStudent: true,
	},
  {
		path: STUDENT_ROUTE.THANK_YOU,
		exact: true,
		component: lazy(() => import('@iso/pages/frontend/ThankYou')),
    isStudent: true,
	},
  // From other site (BPTS)
  {
    path: PUBLIC_ROUTE.ALOGIN,
    component: lazy(() => import('@iso/containers/Pages/ALogin/ALogin')),
  },
];

const StudentLogin = lazy(() => import('@iso/pages/frontend/Login'));

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/webpanel/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const { width, height } = useWindowSize();
  const [isStdLogin, setStdLogin] = React.useState(true);
  const stdLogin = useSelector((state) => state.student.idToken);

  return (
    // <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              {route.isStudent ? <StudentContainer>
                <GlobalStyles />
                <StudentGlobalStyles />
                <div style={{ height: height }} className="wrapper">
                  {isStdLogin ?
                    <>
                      <TopbarStudent />
                      <Content className="isomorphicContent" >
                        <route.component />
                      </Content>
                      <Footer className="footer-wrapper" style={styles.footer}>{siteConfig.studentCR}</Footer>
                    </> :
                    <>
                      <TopbarStudent />
                      <Content className="isomorphicContent" >
                        <StudentLogin />
                        <Redirect
                          to={{
                            pathname: '/login',
                          }}
                        />
                      </Content>
                      <Footer className="footer-wrapper" style={styles.footer}>{siteConfig.studentCR}</Footer>
                    </>
                  }
                  <ThemeSwitcher />
                </div>
              </StudentContainer> : <route.component />}
            </Route>
          ))}
          <PrivateRoute path="/webpanel">
            <Dashboard />
          </PrivateRoute>
        </Switch>
      </Router>
    </Suspense>
    // </ErrorBoundary>
  );
}
