import jwt_decode from "jwt-decode";

let token = localStorage.getItem("STD_ACCESS_TOKEN");

const initState = { 
  isLoggedIn : token!=null ? true : false,
  user: token!=null ? jwt_decode(token) : null
};

export default function userReducers(state = initState, action) {
  switch (action.type) {
    case 'STUDENT_LOGIN':
      console.log('asd')
      return {
        isLoggedIn: true,
        user: localStorage.getItem("STD_ACCESS_TOKEN")!=null ? jwt_decode(localStorage.getItem("STD_ACCESS_TOKEN")) : null
      };
    case 'STUDENT_LOGOUT':
      return initState;
    default:
      return state;
  }
}
